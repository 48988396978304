<template>
  <div>
    <b-card title="Güncelle">
      <b-form-group
        label="Kategori"
        label-for="category"
      >
        <v-select
          id="category"
          v-model="formData.id_faq_categories"
          label="title"
          :options="faqCategoriesData"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="formData.title"
          placeholder="Başlık"
          class="mb-2"
        />
      </b-form-group>
      <b-form-group
        label="İçerik"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="formData.content"
          placeholder="İçerik"
          class="mb-2"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BFormInput, BCard, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Edit',
  components: {
    BFormInput,
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        content: null,
        id_faq_categories: null,
      },
      option: [{ id: '1', title: 'Aktif' }, { id: '0', title: 'Pasif' }],
    }
  },
  computed: {
    faqData() {
      return this.$store.getters['faq/getFaq']
    },
    faqCategoriesData() {
      return this.$store.getters['faqCategories/getFaqCategories']
    },
    saveStatus() {
      return this.$store.getters['faq/getFaqSaveStatus']
    },
  },
  watch: {
    faqData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
    this.faqCategories()
  },
  methods: {
    getData() {
      this.$store.dispatch('faq/faqView', this.$route.params.id)
    },
    faqCategories() {
      this.$store.dispatch('faqCategories/faqCategoriesList')
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('faq/faqSave', this.formData)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
